import { frontendURL } from '../../../../helper/URLHelper';
import { FEATURE_FLAGS } from '../../../../featureFlags';

const campaigns = accountId => ({
  parentNav: 'campaigns',
  routes: ['ongoing_campaigns', 'one_off', 'mass_outbound'],
  menuItems: [
    {
      icon: 'arrow-swap',
      label: 'ONGOING',
      key: 'ongoingCampaigns',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/campaigns/ongoing`),
      toStateName: 'ongoing_campaigns',
    },
    {
      key: 'oneOffCampaigns',
      icon: 'sound-source',
      label: 'ONE_OFF',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/campaigns/one_off`),
      toStateName: 'one_off',
    },
    {
      key: 'massOutboundCampaigns',
      icon: 'send',
      label: 'MASS_OUTBOUND',
      featureFlag: FEATURE_FLAGS.MASS_OUTBOUND,
      hasSubMenu: false,
      meta: {
        permissions: ['administrator'],
      },
      toState: frontendURL(`accounts/${accountId}/campaigns/mass_outbound`),
      toStateName: 'mass_outbound',
    },
  ],
});

export default campaigns;
