<script>
import { frontendURL } from 'dashboard/helper/URLHelper';

export default {
  props: {
    source: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    accountId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    dashboardPath() {
      return frontendURL(`accounts/${this.accountId}/dashboard`);
    },
  },
};
</script>

<template>
  <div class="w-8 h-8">
    <router-link :to="dashboardPath" replace>
      <img src="/brand-assets/logo_thumbnail.svg" alt="FlipDesk" class="dark:hidden block">
      <img src="/brand-assets/logo_thumbnail_dark.svg" alt="FlipDesk" class="dark:block hidden">
    </router-link>
  </div>
</template>
