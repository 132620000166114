<script>
import { mapGetters } from 'vuex';
import { useAdmin } from 'dashboard/composables/useAdmin';
import { useAccount } from 'dashboard/composables/useAccount';
import Banner from 'dashboard/components/ui/Banner.vue';

const EMPTY_SUBSCRIPTION_INFO = {
  status: null,
  endsOn: null,
};

export default {
  components: { Banner },
  setup() {
    const { isAdmin } = useAdmin();

    const { accountId } = useAccount();

    return {
      accountId,
      isAdmin,
    };
  },
  computed: {
    ...mapGetters({
      isOnChatwootCloud: 'globalConfig/isOnChatwootCloud',
      getAccount: 'accounts/getAccount',
      currentUser: 'getCurrentUser',
    }),
    bannerMessage() {
      return this.$t('GENERAL_SETTINGS.PAYMENT_PENDING');
    },
    actionButtonMessage() {
      return this.$t('GENERAL_SETTINGS.OPEN_BILLING');
    },
    shouldShowBanner() {
      if (!this.isAdmin) {
        return false;
      }

      return this.isPaymentPending();
    },
  },
  methods: {
    routeToBilling() {
      const access_token = this.currentUser.access_token;

      const url = `https://www.flipdesk.com.br/conta/${this.accountId.toString()}?access_token=${access_token}`;
      window.location.href = url;
    },
    isPaymentPending() {
      const { status } = this.getSubscriptionInfo();

      if (status === 'past_due') {
        return true;
      }

      return false;
    },
    getSubscriptionInfo() {
      const account = this.getAccount(this.accountId);
      if (!account) return EMPTY_SUBSCRIPTION_INFO;

      const { custom_attributes: subscription } = account;
      if (!subscription) return EMPTY_SUBSCRIPTION_INFO;

      const { subscription_status: status } =
        subscription;

      return { status };
    },
  },
};
</script>

<template>
  <Banner v-if="shouldShowBanner" color-scheme="warning" :banner-message="bannerMessage"
    :action-button-label="actionButtonMessage" has-action-button @click="routeToBilling" />
</template>
